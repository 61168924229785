import React, { Component } from 'react'
// Bootstrap
import { Navbar } from 'react-bootstrap'
// Router
import { NavLink } from 'react-router-dom'
// Logo
import logo from 'assets/img/hsse-maersk-inland-services.svg'

class PagesHeader extends Component {
  constructor (props) {
    super(props)
  }

  activeRoute (routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  render () {
    return (
      <Navbar
        collapseOnSelect
        inverse
        className='navbar-primary navbar-transparent navbar-absolute'
      >
        <Navbar.Header>
          <Navbar.Brand>
            <NavLink to='/dashboard' className='nav-link'>
              <img width='340' src={logo} />
            </NavLink>
          </Navbar.Brand>
        </Navbar.Header>
      </Navbar>
    )
  }
}

export default PagesHeader
