import React, { useEffect, useState, useContext } from 'react'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'config/firebase'
// Router
import {
  useParams,
  useHistory,
  useLocation
} from 'react-router-dom'
// Bootstrap
import {
  Grid,
  Row,
  Col,
  FormGroup,
  InputGroup,
  FormControl,
  Badge
} from 'react-bootstrap'
// Dates
import { format } from 'date-fns'
// Components
import { UserIncidentsInfo } from './UserIncidentsInfo'
import { UserIncidentsFeedback } from './UserIncidentsFeedback'

const styles = {
  incidentItemContainer: {
    boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)',
    margin: '18px 0px',
    padding: '10px'
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dateContainer: {
    borderRight: '2px solid #e7e7e7'
  },
  noMargin: {
    padding: 0,
    margin: 0
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  itemsCol: {
    overflow: 'auto',
    height: '100%',
    paddingLeft: '0'
  },
  detailContiner: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)',
    backgroundColor: '#fafafa',
    height: '100%',
    borderRadius: '4px'
  },
  tabsContainer: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid #E7E7E7'
  },
  tabContainer: {
    flex: 1,
    textAlign: 'center'
  },
  tab: {
    position: 'relative',
    fontWeight: 'bold',
    margin: 0,
    paddingBottom: '4px',
    display: 'inline-block',
    cursor: 'pointer'
  },
  underline: {
    width: '100%',
    height: '5px',
    backgroundColor: '#48B1D3'
  },
  headerContainer: {
    borderBottom: '1px solid #E7E7E7',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  headerItem: {
    flex: 1,
    textAlign: 'center'
  },
  incidentBody: {
    overflow: 'overlay',
    flex: 1,
    backgroundColor: 'white'
  }
}

export const UserIncidents = ({ user }) => {
  const context = useContext(UserContext)
  const location = useLocation()
  const history = useHistory()
  const { incidentId, incidentTab } = useParams()
  const [incidents, setIncidents] = useState([])
  const [filter, setFilter] = useState()
  const [detailTab, setDetailTab] = useState(incidentTab || 'info')
  const [selectedIncident, setSelectedIncident] = useState()

  const getBadge = (tab, id) => {
    if (
      context.notifications.filter(
        notification => notification.topTab === tab && notification.incidentId === selectedIncident?.id
      ).length > 0
    ) {
      return (
        <Badge
          style={{ position: 'absolute', top: -4, left: -20 }}
          className='badge-danger'
        >
          {
              context.notifications.filter(
                notification => notification.topTab === tab
              ).length
            }
        </Badge>
      )
    } else {
      return null
    }
  }

  const getBadgeIncident = id => {
    if (
      context.notifications.filter(
        notification => notification.incidentId === id
      ).length > 0
    ) {
      return (
        <Badge
          style={{ position: 'absolute', top: -4, left: 0 }}
          className='badge-danger'
        >
          {
            context.notifications.filter(
              notification => notification.incidentId === id
            ).length
          }
        </Badge>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    if (user) {
      let incidentsRef = firebase
        .firestore()
        .collection('incidents')

      if (context.user.role === 'admin') {
        incidentsRef = incidentsRef.where('countryISO', 'in', context.user?.regions)
      }

      incidentsRef.where('createdBy.uid', '==', user.id)
        .orderBy('createdAt', 'desc')
        .onSnapshot(snapshot => {
          if (snapshot.docs.length > 0) {
            setIncidents(
              snapshot.docs.map(incident => {
                return {
                  id: incident.id,
                  ...incident.data()
                }
              })
            )
          } else {
            setIncidents([])
          }
        })
    }
  }, [user])

  useEffect(() => {
    if (incidents && !selectedIncident) {
      if (incidentId) {
        const selectedIncidentFromUrl = incidents.filter(item => item.id === incidentId)
        if (selectedIncidentFromUrl) {
          setSelectedIncident(selectedIncidentFromUrl[0])
        } else {
          setSelectedIncident(incidents[0])
        }
      } else {
        setSelectedIncident(incidents[0])
      }
    }
  }, [incidents])

  useEffect(() => {
    if (selectedIncident) {
      if (incidentTab) {
        history.replace(`/admin/user/${user.id}/incidents/${selectedIncident.id}/${incidentTab}`)
      } else {
        history.replace(`/admin/user/${user.id}/incidents/${selectedIncident.id}/info`)
      }
    }
  }, [selectedIncident])

  useEffect(() => {
    setDetailTab(incidentTab)
  }, [location])

  const getBorderRiskColor = incident => {
    if (incident.someoneInjured && incident.injureOrFatality === 'fatality') {
      return '10px solid #fb404b'
    } else if (incident.someoneInjured && incident.injureOrFatality === 'injury') {
      return '10px solid #ffa534'
    } else {
      return '10px solid #87cb16'
    }
  }

  const IcidentItem = ({ incident }) => {
    return (
      <div
        onClick={() => setSelectedIncident(incident)}
        style={{
          ...styles.incidentItemContainer,
          borderLeft: getBorderRiskColor(incident),
          backgroundColor: selectedIncident?.id === incident?.id ? '#F3F3F3' : '#FAFAFA'
        }}
      >
        <Grid fluid>
          <Row style={styles.rowContainer}>
            <Col
              style={styles.dateContainer}
              className='text-center'
              md={4}
            >
              {getBadgeIncident(incident?.id)}
              <h1
                style={styles.noMargin}
                className='text-info'
              >
                <b>{format(incident.createdAt.toDate(), 'd')}</b>
              </h1>
              <h5
                style={styles.noMargin}
                className='text-primary'
              >
                <b>{format(incident.createdAt.toDate(), 'MMM yyyy')}</b>
              </h5>
              <h6
                style={{
                  margin: '4px'
                }}
                className='text-muted'
              >
                {format(incident.createdAt.toDate(), 'HH:mm')}
              </h6>
            </Col>
            <Col style={styles.infoContainer} md={8}>
              <div>
                <h5
                  style={styles.noMargin}
                  className='text-primary'
                >
                  <b>Container Number</b>
                </h5>
                <h5
                  style={{
                    margin: 0,
                    marginTop: '4px'
                  }}
                  className='text-muted'
                >
                  {incident.container}
                </h5>
              </div>
              <div>
                <h4
                  style={{
                    margin: 0,
                    marginTop: '10px'
                  }}
                  className='text-muted'
                >
                  <small>#{incident.incidentId}</small>
                </h4>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }

  return (
    <Grid fluid>
      <Row style={{
        height: '800px'
      }}
      >
        <Col
          style={styles.itemsCol}
          md={4}
        >
          <FormGroup>
            <InputGroup>
              <FormControl
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder='Search incident...'
                type='text'
              />
              <InputGroup.Addon>
                <i className='fa fa-search text-muted' />
              </InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          {incidents.filter(incident => {
            if (!filter) {
              return true
            } else {
              return incident.container.includes(filter) || (incident.incidentId).toString().includes(filter)
            }
          }).map(
            incident => <IcidentItem key={incident.id} incident={incident} />
          )}
        </Col>
        <Col
          style={styles.detailContiner}
          md={8}
        >
          <div style={styles.tabsContainer}>
            <div
              onClick={() => history.push(`/admin/user/${user.id}/incidents/${selectedIncident.id}/info`)}
              style={styles.tabContainer}
            >
              <h3 style={styles.tab}>
                {getBadge('info')}
                Info
                {detailTab === 'info' && (
                  <div style={styles.underline} />
                )}
              </h3>

            </div>
            <div
              onClick={() => history.push(`/admin/user/${user.id}/incidents/${selectedIncident.id}/feedback`)}
              style={styles.tabContainer}
            >
              <h3 style={styles.tab}>
                {getBadge('feedback')}
                Feedback
                {detailTab === 'feedback' && (
                  <div style={styles.underline} />
                )}
              </h3>

            </div>
          </div>
          <div style={styles.headerContainer}>
            <h5
              style={styles.headerItem}
              className='text-muted'
            >
              <b>Created:</b> {selectedIncident?.createdAt && format(selectedIncident?.createdAt.toDate(), 'MMM yyyy HH:mm')}
            </h5>
            <h5
              style={styles.headerItem}
              className='text-muted'
            >
              <b>Incident ID:</b> #{selectedIncident?.incidentId}
            </h5>
          </div>
          <div style={styles.incidentBody}>
            {detailTab === 'info' && selectedIncident && (
              <UserIncidentsInfo selectedIncident={selectedIncident} />
            )}
            {detailTab === 'feedback' && selectedIncident && (
              <div>
                <UserIncidentsFeedback user={user} incidentId={selectedIncident.id} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Grid>
  )
}
