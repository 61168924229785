import Dashboard from 'views/Dashboard'
import { Inductions } from 'views/Inductions'
import { User } from 'views/User'
import { Incidents } from 'views/Incidents'
import Questions from 'views/Questions'
import LoginPage from 'views/LoginPage'
import ForgotPassword from 'views/ForgotPassword'

const routes = [
  {
    path: '/dashboard',
    id: 'dashboard',
    layout: '/admin',
    name: 'Dashboard',
    icon: 'pe-7s-graph',
    component: Dashboard
  },
  {
    path: '/inductions',
    id: 'inductions',
    layout: '/admin',
    name: 'Inductions',
    icon: 'pe-7s-note2',
    component: Inductions
  },
  {
    path: '/incidents',
    id: 'incidents',
    layout: '/admin',
    name: 'Incidents',
    icon: 'pe-7s-attention',
    component: Incidents
  },
  {
    path: '/questions',
    id: 'questions',
    layout: '/admin',
    name: 'Questions',
    icon: 'pe-7s-phone',
    component: Questions
  },
  {
    path: '/user/:userId/:tab/:incidentId/:incidentTab',
    layout: '/admin',
    name: 'Info',
    component: User,
    redirect: true
  },
  {
    path: '/user/:userId/:tab',
    layout: '/admin',
    name: 'Info',
    component: User,
    redirect: true
  },
  {
    path: '/login',
    layout: '/auth',
    name: 'Login Page',
    mini: 'LP',
    component: LoginPage,
    redirect: true
  },
  {
    path: '/forgot-password',
    layout: '/auth',
    name: 'Forgot Password',
    mini: 'FP',
    component: ForgotPassword,
    redirect: true
  }
]
export default routes
