import React, { Component } from 'react'
// Images
import cactus from 'assets/img/outline-cactus.svg'
import cactusGreen from 'assets/img/outline-cactus-green.svg'

class Footer extends Component {
  render () {
    return (
      <footer
        className={
          'footer' +
          (this.props.transparent !== undefined ? ' footer-transparent' : '')
        }
      >
        <div
          className={
            'container' + (this.props.fluid !== undefined ? '-fluid' : '')
          }
        >
          <nav className='pull-left'>
            <ul>
              <li>
                <a href='#maersk'>Linkeding</a>
              </li>
              <li>
                <a href='#maersk'>Facebook</a>
              </li>
              <li>
                <a href='#maersk'>Twitter</a>
              </li>
              <li>
                <a href='#maersk'>Instagram</a>
              </li>
              <li>
                <a href='#maersk'>YouTube</a>
              </li>
            </ul>
          </nav>
          <p className='copyright pull-right'>
            &copy; {1900 + new Date().getYear()}{' '}
            HSSE Maersk Landside Services |  {' '}
            Developed by {' '}
            <a href='https://estudiocactus.com' rel='noreferrer' target='_blank'>

              {this.props.transparent
                ? <img className='logo' height='18' src={cactus} />
                : <img className='logo' height='18' src={cactusGreen} />}
              {' '}
              <span
                style={{
                  color: this.props.transparent ? 'white' : '#709E00'
                }}
                className='cactus-font'
              >
                Estudio Cactus
              </span>
            </a>
          </p>
        </div>
      </footer>
    )
  }
}
export default Footer
