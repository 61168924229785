import React, { useEffect, useState } from 'react'
// Firebase
import firebase from 'config/firebase'

export const PreviewThumb = props => {
  const [urlImage, setUrlImage] = useState(null)

  useEffect(() => {
    if ('incidentId' in props && 'imageId' in props) {
      const path = `incidents/${props.incidentId}/image${props.imageId}_200x200.jpg`
      firebase.storage().ref().child(path).getDownloadURL().then(url => {
        setUrlImage(url)
      })
    }
  }, [props.incidentId, props.imageId])

  return (
    <img
      src={urlImage}
      style={styles.imageAttached}
      {...props}
    />
  )
}

const styles = {
  imageAttached: {
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: 4,
    margin: 4,
    width: 68,
    height: 68
  }
}
