export const ROLES = {
  truckDriver: {
    name: 'Truck Driver'
  },
  whOperator: {
    name: 'WH Operator'
  },
  whMaintenance: {
    name: 'WH Maintenance'
  },
  visitor: {
    name: 'Visitor'
  }
}
