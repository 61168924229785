import React, { Component } from 'react'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'config/firebase'
import { Switch, Route } from 'react-router-dom'
// import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import NotificationSystem from 'react-notification-system'
// Components
import Loader from 'components/Loader/Loader'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx'
import Footer from 'components/Footer/Footer.jsx'
// assets
import image from 'assets/img/truck-bg.webp'
// Routes
import routes from 'routes.js'

// let ps

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeRouteName: '',
      user: {},
      notifications: [],
      facilities: [],
      loading: true,
      _notificationSystem: null,
      image: image,
      color: 'maersk',
      hasImage: true,
      navbar: false,
      mini: false,
      fixedClasses: 'dropdown show-dropdown open',
      showOverlay: false
    }
  }

  componentDidMount () {
    // this.setState({ _notificationSystem: this.refs.notificationSystem })
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps = new PerfectScrollbar(this.refs.mainPanel)
    // }
  }

  componentWillUnmount () {
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps.destroy()
    // }
  }

  componentDidUpdate (e) {
    // if (navigator.platform.indexOf('Win') > -1) {
    //   setTimeout(() => {
    //     ps.update()
    //   }, 350)
    // }
    // if (e.history.action === 'PUSH') {
    //   document.documentElement.scrollTop = 0
    //   document.scrollingElement.scrollTop = 0
    //   this.refs.mainPanel.scrollTop = 0
    // }
    // if (
    //   window.innerWidth < 993 &&
    //   e.history.action === 'PUSH' &&
    //   document.documentElement.className.indexOf('nav-open') !== -1
    // ) {
    //   document.documentElement.classList.toggle('nav-open')
    // }
  }

  componentWillMount () {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open')
    }

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        firebase.firestore()
          .collection('admins')
          .doc(user.uid)
          .onSnapshot(snapshot => {
            if (!snapshot.empty) {
              this.setState({
                user: {
                  id: user.uid,
                  ...snapshot.data()
                }
              }, () => {
                this.setState({ loading: false })
              })

              window.OneSignal.push(function () {
                window.OneSignal.getUserId().then(result => {
                  if (result) {
                    firebase.firestore()
                      .collection('admins')
                      .doc(user.uid)
                      .update({
                        oneSignal: result
                      })
                  }
                }).catch(err => {
                  console.log(err)
                })
              })

              let facilitiesRef = firebase.firestore()
                .collection('facilities')
              if (snapshot.data().role === 'admin') {
                facilitiesRef = facilitiesRef.where('countryISO', 'in', snapshot.data().regions)
              }
              facilitiesRef.onSnapshot(snapshotFacilities => {
                if (snapshotFacilities.docs.length > 0) {
                  this.setState({
                    facilities: snapshotFacilities.docs
                      .filter(facility => facility.id !== 'default')
                      .map(facility => {
                        return {
                          id: facility.id,
                          ...facility.data()
                        }
                      })
                  })
                } else {
                  this.setState({ facilities: [] })
                }
              })
            } else {
              this.setState({ user: {}, loading: false })
              this.props.history.push('/auth/login')
            }
          })

        firebase.firestore()
          .collection('admins')
          .doc(user.uid)
          .collection('notifications')
          .orderBy('createdAt', 'desc')
          .onSnapshot(snapshot => {
            if (snapshot.docs.length > 0) {
              this.setState({
                notifications: snapshot.docs.map(notification => {
                  return {
                    id: notification.id,
                    ...notification.data()
                  }
                })
              })
            } else {
              this.setState({ notifications: [] })
            }
          })
      } else {
        this.setState({ user: {}, loading: false })
        this.props.history.push('/auth/login')
      }
    })
  }

  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick = position => {
    const color = Math.floor(Math.random() * 4 + 1)
    let level
    switch (color) {
      case 1:
        level = 'success'
        break
      case 2:
        level = 'warning'
        break
      case 3:
        level = 'error'
        break
      case 4:
        level = 'info'
        break
      default:
        break
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify='icon' className='pe-7s-gift' />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    })
  };

  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini })
    document.body.classList.toggle('sidebar-mini')
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={routeProps => (
              <prop.component
                {...routeProps}
                handleClick={this.handleNotificationClick}
              />
            )}
          />
        )
      } else {
        return null
      }
    })
  };

  setActiveRouteName = name => {
    this.setState({ activeRouteName: name })
  }

  render () {
    if (this.state.loading) {
      return (
        <div
          className='main-content'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
          ref='mainContent'
        >
          <Loader />
        </div>
      )
    } else {
      return (
        <UserContext.Provider
          value={{
            user: this.state.user,
            notifications: this.state.notifications,
            facilities: this.state.facilities,
            activeRouteName: this.state.activeRouteName,
            setActiveRouteName: name => this.setActiveRouteName(name),
            showOverlay: this.state.showOverlay,
            setShowOverlay: show => this.setState({ showOverlay: show })
          }}
        >
          <div className='wrapper'>
            <NotificationSystem ref='notificationSystem' />
            <Sidebar
              {...this.props}
              image={this.state.image}
              color={this.state.color}
              hasImage={this.state.hasImage}
              mini={this.state.mini}
            />
            <div
              className={
              'main-panel' +
              (this.props.location.pathname === '/maps/full-screen-maps'
                ? ' main-panel-maps'
                : '')
            }
              ref='mainPanel'
            >
              <AdminNavbar
                {...this.props}
                handleMiniClick={this.handleMiniClick}
                navbar={this.state.navbar}
              />
              {this.state.showOverlay && <div onClick={() => this.setState({ showOverlay: false })} className='overlay-search' />}
              <Switch>{this.getRoutes(routes)}</Switch>
              <Footer fluid />
            </div>
          </div>
        </UserContext.Provider>
      )
    }
  }
}

export default Dashboard
