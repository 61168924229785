import React from 'react'
// Dates
import { format } from 'date-fns'

export const FeedbackMessage = (props) => {
  const styles = {
    container: {
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'column'
    },
    usernameContainer: {
      justifyContent: props.item.createdBy.uid === 'maersk' ? 'flex-end' : 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    username: {
      margin: 0,
      fontWeight: 'bold',
      marginLeft: 10
    },
    messageContainer: {
      boxShadow: '0 1px 2px 0 rgba(0,0,0,.2)',
      marginTop: 4,
      minWidth: '40%',
      maxWidth: '80%',
      justifyContent: props.item.createdBy.uid === 'maersk' ? 'flex-end' : 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: props.item.createdBy.uid === 'maersk' ? 'flex-end' : 'flex-start',
      backgroundColor: '#F3F3F3',
      padding: 8,
      borderRadius: 6
    },
    message: {
      color: '#02243C',
      fontSize: 18,
      textAlign: props.item.createdBy.uid === 'maersk' ? 'left' : 'left'
    },
    date: {
      marginTop: 4,
      color: '#ccc',
      fontSize: 12,
      textAlign: 'right'
    }
  }
  return (
    <div style={styles.container}>
      <div style={styles.usernameContainer}>
        {props.item.createdBy.uid === 'maersk'
          ? (
            <img width='24' src={require('assets/img/maersk-star.svg')} />
            )
          : (
            <i style={{ fontSize: 20 }} className='fas fa-user-alt' />
            )}
        <h4
          style={styles.username}
        >
          {props.item.createdBy.fullName}
        </h4>
      </div>
      <div style={styles.messageContainer}>
        <p style={styles.message}>
          {props.item.message}
        </p>
        <p style={styles.date}>{format(props.item.createdAt.toDate(), 'd MMM HH:mm')}</p>
      </div>
    </div>
  )
}
