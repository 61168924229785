import React, { useState, useContext, useEffect } from 'react'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'config/firebase'
// Lightbox
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
// Components
import { PreviewThumb } from 'components/PreviewThumb'
// Map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'
// Bootstrap
import {
  Row,
  Col
} from 'react-bootstrap'
// Country Lookup
const lookup = require('country-code-lookup')

const styles = {
  noMargin: {
    padding: 0,
    margin: 0
  },
  image: {
    objectFit: 'cover',
    borderRadius: '4px',
    boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
    margin: '6px'
  },
  container: {
    padding: 20
  }
}

export const UserIncidentsInfo = ({ selectedIncident }) => {
  const authContext = useContext(UserContext)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [lightboxOpen, setLightboxOpen] = useState(false)

  const checkForNotificationsToDelete = () => {
    const notifications = authContext.notifications.filter(notification => notification.incidentId === selectedIncident.id && notification.topTab === 'info')
    if (notifications.length > 0) {
      notifications.forEach(n => {
        firebase.firestore()
          .collection('admins')
          .doc(authContext.user.id)
          .collection('notifications')
          .doc(n.id)
          .delete()
      })
    }
  }

  useEffect(() => {
    checkForNotificationsToDelete()
  }, [selectedIncident, authContext.notifications])

  const getRiskCategory = () => {
    if (selectedIncident.someoneInjured && selectedIncident.injureOrFatality === 'fatality') {
      return (
        <>
          <i className='fa fa-circle text-danger' /> {' '}
          <b className='text-danger'>Fatal Case</b>
        </>
      )
    } else if (selectedIncident.someoneInjured && selectedIncident.injureOrFatality === 'injury') {
      return (
        <>
          <i className='fa fa-circle text-warning' /> {' '}
          <b className='text-warning'>LTI</b>
        </>
      )
    } else {
      return (
        <>
          <i className='fa fa-circle text-success' /> {' '}
          <b className='text-success'>Non-LTI</b>
        </>
      )
    }
  }

  const RegularMap = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={14}
        defaultCenter={{
          lat: selectedIncident?.location?.latitude,
          lng: selectedIncident?.location?.longitude
        }}
        defaultOptions={{
          scrollwheel: false
        }}
      >
        <Marker position={{
          lat: selectedIncident?.location?.latitude,
          lng: selectedIncident?.location?.longitude
        }}
        />
      </GoogleMap>
    ))
  )

  return (
    <div>
      <RegularMap
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDEHJPG3HefRU5Q78F_cK3C-a-_7ugfxMA'
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '280px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
      {selectedIncident && (
        <div style={styles.container}>

          <Row style={{ marginBottom: 20 }}>
            <Col md={6} xs={12}>
              <h4 style={styles.noMargin}>
                <b>Risk Category</b>
              </h4>
              <h4 style={styles.noMargin} className='text-info'>
                <b>
                  {getRiskCategory()}
                </b>
              </h4>
            </Col>
            <Col md={6} xs={12}>
              <h4 style={styles.noMargin}>
                <b>Container number</b>
              </h4>
              <h4 style={styles.noMargin} className='text-muted'>
                <b>{selectedIncident?.container}</b>
              </h4>
            </Col>
          </Row>
          <hr />
          <Row style={{ marginBottom: 20 }}>
            <Col md={6} xs={12}>
              <h5 style={styles.noMargin}>
                <b>Was someone injured?</b>
              </h5>
              <h5 style={styles.noMargin} className='text-info'>
                <b>{selectedIncident?.someoneInjured ? 'Yes' : 'No'}</b>
              </h5>
            </Col>
            <Col md={6} xs={12}>
              <h5 style={styles.noMargin}>
                <b>Did the incident lead to an injury or fatality?</b>
              </h5>
              <h5 style={styles.noMargin} className='text-info'>
                <b>{selectedIncident?.injureOrFatality}</b>
              </h5>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md={6} xs={12}>
              <h5 style={styles.noMargin}>
                <b>Did the injured person return to work the next day?</b>
              </h5>
              <h5 style={styles.noMargin} className='text-info'>
                <b>{selectedIncident?.personNextDay ? 'Yes' : 'No'}</b>
              </h5>
            </Col>
            <Col md={6} xs={12}>
              <h5 style={styles.noMargin}>
                <b>Did the incident cause a fire or a spillage?</b>
              </h5>
              <h5 style={styles.noMargin} className='text-info'>
                <b>{selectedIncident?.fireOrSpillage}</b>
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <h5 style={styles.noMargin}>
                <b>Country</b>
              </h5>
              <h5 style={styles.noMargin} className='text-info'>
                <img
                  key={selectedIncident?.countryISO}
                  alt='flag'
                  style={{ marginRight: '6px' }}
                  src={`https://flagcdn.com/20x15/${selectedIncident?.countryISO.toLowerCase()}.png`}
                />
                <b>{lookup.byIso(selectedIncident?.countryISO).country}</b>
              </h5>
            </Col>
            <Col md={6} xs={12}>
              <h5 style={styles.noMargin}>
                <b>Facility</b>
              </h5>
              <h5 style={styles.noMargin} className='text-info'>
                <b>
                  {authContext.facilities.filter(facility => facility.id === selectedIncident?.facility)[0]?.name || 'Unknown'}
                </b>
              </h5>
            </Col>
          </Row>
          <hr />
          <h4 style={styles.noMargin}>
            <b>Description</b>
          </h4>
          <h4 style={styles.noMargin} className='text-muted'>
            <b>{selectedIncident?.description}</b>
          </h4>
          <br />

          <h4 style={styles.noMargin}>
            <b>Images attached</b>
          </h4>
          <br />
          {selectedIncident?.images.map((image, index) => {
            return (
              <PreviewThumb
                key={index}
                incidentId={selectedIncident.id}
                imageId={index}
                onClick={() => {
                  setPhotoIndex(index)
                  setLightboxOpen(true)
                }}
              />
            )
          })}
          {lightboxOpen && (
            <Lightbox
              mainSrc={selectedIncident.images[photoIndex]}
              nextSrc={selectedIncident.images[(photoIndex + 1) % selectedIncident.images.length]}
              prevSrc={selectedIncident.images[(photoIndex + selectedIncident.images.length - 1) % selectedIncident.images.length]}
              onCloseRequest={() => setLightboxOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + selectedIncident.images.length - 1) % selectedIncident.images.length)}
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % selectedIncident.images.length)}
            />
          )}
        </div>
      )}
    </div>
  )
}
