import React, { Component } from 'react'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'config/firebase'
import { Collapse, Badge } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
// this is used to create scrollbars on windows devices like the ones from apple devices
// import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.jsx'

// image for avatar in Sidebar
import defaultAvatar from 'assets/img/default-avatar.png'
// logo for sidebar
import logo from 'assets/img/maersk-star.svg'
import inlandLogo from 'assets/img/logo-inland-services.svg'
import routes from 'routes.js'
// Config
import { ADMIN_ROLES } from 'config/adminRoles'
// let ps

class Sidebar extends Component {
  static contextType = UserContext
  constructor (props) {
    super(props)
    this.state = {
      ...this.getCollapseStates(routes),
      openAvatar: false,
      isWindows: navigator.platform.indexOf('Win') > -1,
      width: window.innerWidth
    }
  }

  getBadge = (tab) => {
    if (
      this.context.notifications.filter(
        notification => notification.tab === tab
      ).length > 0
    ) {
      return (
        <Badge
          style={{ position: 'absolute', left: -12, top: -4 }}
          className='badge-danger'
        >
          {
              this.context.notifications.filter(
                notification => notification.tab === tab
              ).length
            }
        </Badge>
      )
    } else {
      return null
    }
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {}
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        }
      }
      return null
    })
    return initialState
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState (routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      if (prop.collapse) {
        const st = {}
        st[prop.state] = !this.state[prop.state]
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? 'active' : ''}
            key={key}
          >
            <a
              href='#maersk'
              onClick={e => {
                e.preventDefault()
                this.setState(st)
              }}
            >
              <i className={prop.icon} />
              <p>
                {prop.name}
                <b
                  className={
                    this.state[prop.state] ? 'caret rotate-180' : 'caret'
                  }
                />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className='nav'>{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        )
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            className='nav-link'
            activeClassName='active'
          >
            {prop.icon
              ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {this.getBadge(prop.id)}
                    {prop.name}
                  </p>
                </>
                )
              : (
                <>
                  <span className='sidebar-mini'>{prop.mini}</span>
                  <span className='sidebar-normal'>{prop.name}</span>
                </>
                )}
          </NavLink>
        </li>
      )
    })
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  };

  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions () {
    this.setState({ width: window.innerWidth })
  }

  componentDidUpdate () {
    // if (navigator.platform.indexOf('Win') > -1) {
    //   setTimeout(() => {
    //     ps.update()
    //   }, 350)
    // }
  }

  componentDidMount () {
    this.updateDimensions()
    // add event listener for windows resize
    window.addEventListener('resize', this.updateDimensions.bind(this))
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   })
    // }
  }

  componentWillUnmount () {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps.destroy()
    // }
  }

  render () {
    return (
      <div
        className='sidebar'
        data-color={this.props.color}
        data-image={this.props.image}
      >
        {this.props.hasImage &&
          (
            <div
              className='sidebar-background'
              style={{ backgroundImage: 'url(' + this.props.image + ')' }}
            />
          )}
        <div className='logo'>
          <a
            href='/admin/dashboard'
            className='simple-text logo-mini'

          >
            <div className='logo-img'>
              <img src={logo} alt='react-logo' />
            </div>
          </a>
          <a
            href='/admin/dashboard'
            className='simple-text logo-normal'

          >
            <img width='174' src={inlandLogo} />
          </a>
        </div>
        <div className='sidebar-wrapper' ref='sidebarWrapper'>
          <div className='user'>
            <div className='photo'>
              <img src={this.context.user.avatar || defaultAvatar} alt='Avatar' />
            </div>
            <div className='info'>
              <a
                href='#maersk'
                onClick={e => {
                  e.preventDefault()
                  this.setState({ openAvatar: !this.state.openAvatar })
                }}
              >
                <span>
                  {this.context.user.fullName}
                  <b
                    className={
                      this.state.openAvatar ? 'caret rotate-180' : 'caret'
                    }
                  />
                </span>
                <b>{ADMIN_ROLES[this.context.user.role]?.name}</b>
              </a>
              <Collapse in={this.state.openAvatar}>
                <ul className='nav'>
                  <li>
                    <a href='' onClick={() => firebase.auth().signOut()}>
                      <span className='sidebar-mini'>L</span>
                      <span className='sidebar-normal'>Log Out</span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <ul className='nav'>
            {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
            {this.createLinks(routes)}
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar
