import React, { useState, useEffect, useContext } from 'react'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'config/firebase'
// Bootstrap
import { Grid, Col, Row } from 'react-bootstrap'
// Charts
import ChartistGraph from 'react-chartist'
// Map
import { VectorMap } from 'react-jvectormap'
// Components
import Card from 'components/Card/Card.jsx'
import StatsCard from 'components/Card/StatsCard.jsx'
// Table
import ReactTable from 'react-table'
// Date
import { format } from 'date-fns'
// Utils
import { getIncidentType } from 'utils/incidentUtils'
import { AREAS } from 'config/areas'
// Dropdown
import Dropdown from 'components/Dropdown/Dropdown.jsx'
// Config
import { ROLES } from 'config/roles'
// Country Lookup
const lookup = require('country-code-lookup')
// Underscore
const _ = require('underscore')

const CURRENT_YEAR = 2022

const Dashboard = () => {
  const context = useContext(UserContext)
  const [selectedUserType, setSelectedUserType] = useState({
    label: 'Truck Driver',
    value: 'truckDriver'
  })
  const [allUsers, setAllUsers] = useState()
  const [allIncidents, setAllIncidents] = useState([])

  useEffect(() => {
    firebase.firestore()
      .collection('users')
      .onSnapshot(snapshot => {
        const usersInfo = snapshot.docs.map(i => i.data())
        setAllUsers(_.countBy(usersInfo, 'type'))
      })

    let incidentsRef = firebase.firestore()
      .collection('incidents')

    if (context.user.role === 'admin') {
      incidentsRef = incidentsRef.where('countryISO', 'in', context.user?.regions)
    }

    incidentsRef.where('createdBy.type', '==', selectedUserType.value)
      .onSnapshot(snapshot => {
        if (snapshot.docs.length > 0) {
          const allIncidentsAux = []
          snapshot.docs.forEach(incident => {
            allIncidentsAux.push({
              id: incident.id,
              ...incident.data()
            })
          })
          setAllIncidents(allIncidentsAux)
        } else {
          setAllIncidents([])
        }
      })
  }, [selectedUserType])

  const getMapData = (type) => {
    const countries = {}
    allIncidents.forEach(incident => {
      if (getIncidentType(incident) === type) {
        countries[incident.countryISO] = (countries[incident.countryISO] + 1) || 1
      }
    })
    return countries
  }

  const getIncidentsByArea = area => {
    if (AREAS[area].regions.length > 0) {
      let sum = 0
      allIncidents.forEach(incident => {
        if (AREAS[area].regions.includes(incident.countryISO)) {
          sum++
        }
      })
      return sum
    } else {
      return 0
    }
  }

  const getIncidentsByAreaAndType = (area, type) => {
    if (AREAS[area].regions.length > 0) {
      let sum = 0
      allIncidents.forEach(incident => {
        if (AREAS[area].regions.includes(incident.countryISO)) {
          if (getIncidentType(incident) === type) {
            sum++
          }
        }
      })
      return sum
    } else {
      return 0
    }
  }

  const getIncidentsByAreaAndCountry = (area, country) => {
    if (AREAS[area].regions.length > 0) {
      let sum = 0
      allIncidents.forEach(incident => {
        if (AREAS[area].regions.includes(incident.countryISO)) {
          if (incident.countryISO === country) {
            sum++
          }
        }
      })
      return sum
    } else {
      return 0
    }
  }

  const getIncidentsByAreaCountryAndType = (area, country, type) => {
    if (AREAS[area].regions.length > 0) {
      let sum = 0
      allIncidents.forEach(incident => {
        if (AREAS[area].regions.includes(incident.countryISO)) {
          if (incident.countryISO === country) {
            if (getIncidentType(incident) === type) {
              sum++
            }
          }
        }
      })
      return sum
    } else {
      return 0
    }
  }

  const getIncidentsByFacility = (facility) => {
    return (allIncidents.filter(item => item.facility === facility)).length
  }

  const getIncidentsByFacilityAndType = (facility, type) => {
    return (allIncidents.filter(item => item.facility === facility && getIncidentType(item) === type)).length
  }

  const getIncidentsByCountry = country => {
    return (allIncidents.filter(item => item.countryISO === country)).length
  }

  const getUnknownIncidentsByCountry = country => {
    return (allIncidents.filter(item => item.countryISO === country && item.facility === 'unknown')).length
  }

  const getGlobalIncidents = () => {
    return Object.keys(AREAS).map(area => {
      return {
        area: AREAS[area].name,
        incidents: getIncidentsByArea(area),
        nonLTI: getIncidentsByAreaAndType(area, 'nonLTI'),
        LTI: getIncidentsByAreaAndType(area, 'LTI'),
        fatalCase: getIncidentsByAreaAndType(area, 'fatalCase'),
        total: ((getIncidentsByArea(area) / allIncidents.length) * 100).toFixed(2) + '%',
        regions: AREAS[area].regions
          .filter(region => {
            if (context.user?.role === 'admin') {
              return context.user?.regions.includes(region)
            }
            return true
          })
          .map(region => {
            return {
              country: region,
              incidents: getIncidentsByAreaAndCountry(area, region),
              nonLTI: getIncidentsByAreaCountryAndType(area, region, 'nonLTI'),
              LTI: getIncidentsByAreaCountryAndType(area, region, 'LTI'),
              fatalCase: getIncidentsByAreaCountryAndType(area, region, 'fatalCase'),
              total: ((getIncidentsByAreaAndCountry(area, region) / getIncidentsByArea(area)) * 100).toFixed(2) + '%',
              facilities: context.facilities
                .filter(item => item.id !== 'default')
                .filter(item => item.countryISO === region)
                .concat({
                  id: 'unknown',
                  name: 'Unknown Location',
                  countryISO: 'UNK'
                })
                .map(facility => {
                  if (facility.id === 'unknown') {
                    return {
                      facilityName: facility.name,
                      countryISO: facility.countryISO,
                      incidents: getUnknownIncidentsByCountry(region),
                      nonLTI: getIncidentsByFacilityAndType(facility.id, 'nonLTI'),
                      LTI: getIncidentsByFacilityAndType(facility.id, 'LTI'),
                      fatalCase: getIncidentsByFacilityAndType(facility.id, 'fatalCase'),
                      total: ((getIncidentsByFacility(facility.id) / getIncidentsByCountry(region)) * 100).toFixed(2)
                    }
                  } else {
                    return {
                      facilityName: facility.name,
                      countryISO: facility.countryISO,
                      incidents: getIncidentsByFacility(facility.id),
                      nonLTI: getIncidentsByFacilityAndType(facility.id, 'nonLTI'),
                      LTI: getIncidentsByFacilityAndType(facility.id, 'LTI'),
                      fatalCase: getIncidentsByFacilityAndType(facility.id, 'fatalCase'),
                      total: ((getIncidentsByFacility(facility.id) / getIncidentsByCountry(region)) * 100).toFixed(2)
                    }
                  }
                })
            }
          })
      }
    })
  }

  const getChartPieData = () => {
    const nonLtiPie = ((100 * allIncidents.filter(incident => {
      return getIncidentType(incident) === 'nonLTI'
    }).length) / allIncidents.length).toFixed(0)

    const ltiPie = ((100 * allIncidents.filter(incident => {
      return getIncidentType(incident) === 'LTI'
    }).length) / allIncidents.length).toFixed(0)

    const fatalCasePie = ((100 * allIncidents.filter(incident => {
      return getIncidentType(incident) === 'fatalCase'
    }).length) / allIncidents.length).toFixed(0)

    const labels = []
    const series = []

    if (nonLtiPie > 0) {
      labels.push(nonLtiPie + '%')
      series.push(nonLtiPie)
    } else {
      labels.push('0%')
      series.push(0)
    }
    if (ltiPie > 0) {
      labels.push(ltiPie + '%')
      series.push(ltiPie)
    } else {
      labels.push('0%')
      series.push(0)
    }
    if (fatalCasePie > 0) {
      labels.push(fatalCasePie + '%')
      series.push(fatalCasePie)
    } else {
      labels.push('0%')
      series.push(0)
    }
    return {
      labels,
      series
    }
  }

  const getBarData = () => {
    const series = [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ]
    allIncidents.forEach(incident => {
      const month = format(incident.createdAt.toDate(), 'M')
      const year = format(incident.createdAt.toDate(), 'yyyy')
      if (parseInt(year) === CURRENT_YEAR) {
        const type = getIncidentType(incident)
        if (type === 'fatalCase') {
          series[2][month - 1] = series[2][month - 1] + 1
        } else if (type === 'LTI') {
          series[1][month - 1] = series[1][month - 1] + 1
        } else {
          series[0][month - 1] = series[0][month - 1] + 1
        }
      }
    })

    return {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      series
    }
  }

  return (
    <div className='main-content' style={{ paddingTop: 0 }}>
      <div style={styles.dropdownContainer}>
        <h4 style={{ marginRight: 10, paddingBottom: 10 }}>
          <b className='text-primary'>Stats for user type </b>
        </h4>
        <Dropdown
          width={200}
          value={selectedUserType}
          onChange={value => {
            setSelectedUserType(value)
          }}
          placeholder='User type...'
          options={Object.keys(ROLES).map(role => {
            return {
              label: ROLES[role].name,
              value: role
            }
          })}
        />
      </div>
      <Grid fluid>
        <Row>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className='fa fa-users text-info' />}
              statsText={selectedUserType.label}
              statsValue={allUsers?.[selectedUserType.value] || 0}
              statsIcon={<i className='fa fa-refresh' />}
              statsIconText='Updated now'
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className='fa fa-warning text-success' />}
              statsText='Non-LTI'
              statsValue={allIncidents.filter(incident => {
                return getIncidentType(incident) === 'nonLTI'
              }).length}
              statsIcon={<i className='fa fa-refresh' />}
              statsIconText='Updated now'
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className='fa fa-warning text-warning' />}
              statsText='LTI'
              statsValue={allIncidents.filter(incident => {
                return getIncidentType(incident) === 'LTI'
              }).length}
              statsIcon={<i className='fa fa-refresh' />}
              statsIconText='Updated now'
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className='fa fa-warning text-danger' />}
              statsText='Fatal Case'
              statsValue={allIncidents.filter(incident => {
                return getIncidentType(incident) === 'fatalCase'
              }).length}
              statsIcon={<i className='fa fa-refresh' />}
              statsIconText='Updated now'
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card
              title='Non-LTI'
              category='Incidents regions heat map'
              content={
                <Row>
                  <Col md={12}>
                    <VectorMap
                      map='world_mill'
                      backgroundColor='transparent'
                      zoomOnScroll={false}
                      focusOn={
                        {
                          x: 0.5,
                          y: 0.39,
                          scale: 7
                        }
                      }
                      containerStyle={{
                        width: '100%',
                        height: '280px',
                        background: '#FAFAFA'
                      }}
                      containerClassName='map'
                      regionStyle={{
                        initial: {
                          fill: '#e4e4e4',
                          'fill-opacity': 0.9,
                          stroke: 'none',
                          'stroke-width': 0,
                          'stroke-opacity': 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: getMapData('nonLTI'),
                            scale: ['#e0ffae', '#87CB16'],
                            normalizeFunction: 'polynomial'
                          }
                        ]
                      }}
                      onRegionTipShow={(e, el, code) => {
                        el.html(el.html() + ` (${getMapData('nonLTI')[code]} incidents)`)
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title='LTI'
              category='Incidents regions heat map'
              content={
                <Row>
                  <Col md={12}>
                    <VectorMap
                      map='world_mill'
                      backgroundColor='transparent'
                      zoomOnScroll={false}
                      focusOn={
                        {
                          x: 0.5,
                          y: 0.39,
                          scale: 7
                        }
                      }
                      containerStyle={{
                        width: '100%',
                        height: '280px',
                        background: '#FAFAFA'
                      }}
                      containerClassName='map'
                      regionStyle={{
                        initial: {
                          fill: '#e4e4e4',
                          'fill-opacity': 0.9,
                          stroke: 'none',
                          'stroke-width': 0,
                          'stroke-opacity': 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: getMapData('LTI'),
                            scale: ['#ffd197', '#FFA534'],
                            normalizeFunction: 'polynomial'
                          }
                        ]
                      }}
                      onRegionTipShow={(e, el, code) => {
                        el.html(el.html() + ` (${getMapData('LTI')[code]} incidents)`)
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title='Fatal Cases'
              category='Incidents regions heat map'
              content={
                <Row>
                  <Col md={12}>
                    <VectorMap
                      map='world_mill'
                      backgroundColor='transparent'
                      zoomOnScroll={false}
                      focusOn={
                        {
                          x: 0.5,
                          y: 0.39,
                          scale: 7
                        }
                      }
                      containerStyle={{
                        width: '100%',
                        height: '280px',
                        background: '#FAFAFA'
                      }}
                      containerClassName='map'
                      regionStyle={{
                        initial: {
                          fill: '#e4e4e4',
                          'fill-opacity': 0.9,
                          stroke: 'none',
                          'stroke-width': 0,
                          'stroke-opacity': 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: getMapData('fatalCase'),
                            scale: ['#ffa4a9', '#FB404B'],
                            normalizeFunction: 'polynomial'
                          }
                        ]
                      }}
                      onRegionTipShow={(e, el, code) => {
                        el.html(el.html() + ` (${getMapData('fatalCase')[code]} incidents)`)
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col md={12}>
            <Card
              title='Global Incidents regions'
              category='Incidents regions'
              content={
                <Row>
                  <Col md={12}>
                    <ReactTable
                      previousText='Previous'
                      nextText='Next'
                      loadingText='Loading...'
                      noDataText='No data'
                      pageText='Page'
                      ofText='of'
                      rowsText='Data'
                      data={getGlobalIncidents()}
                      loading={false}
                      filterable={false}
                      defaultPageSize={7}
                      defaultSorted={[{
                        id: 'incidents',
                        desc: true
                      }]}
                      showPaginationBottom={false}
                      defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        if (id === 'type') {
                          if (filter.value.value === 'all') {
                            return true
                          } else {
                            let filterValue = filter.value
                            if (typeof filterValue === 'string') {
                              filterValue = filter.value
                                .replace('á', 'a')
                                .replace('é', 'e')
                                .replace('í', 'i')
                                .replace('ó', 'o')
                                .replace('ú', 'u')
                            }
                            let rowValue = row[filter.id]
                            if (typeof rowValue === 'string') {
                              rowValue = row[filter.id]
                                .replace('á', 'a')
                                .replace('é', 'e')
                                .replace('í', 'i')
                                .replace('ó', 'o')
                                .replace('ú', 'u')
                            }
                            return new RegExp(filterValue, 'gi').test(
                              String(rowValue)
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                            )
                          }
                        } else {
                          let filterValue = filter.value
                          if (typeof filterValue === 'string') {
                            filterValue = filter.value
                              .replace('á', 'a')
                              .replace('é', 'e')
                              .replace('í', 'i')
                              .replace('ó', 'o')
                              .replace('ú', 'u')
                          }
                          let rowValue = row[filter.id]
                          if (typeof rowValue === 'string') {
                            rowValue = row[filter.id]
                              .replace('á', 'a')
                              .replace('é', 'e')
                              .replace('í', 'i')
                              .replace('ó', 'o')
                              .replace('ú', 'u')
                          }
                          return new RegExp(filterValue, 'gi').test(
                            String(rowValue)
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                          )
                        }
                      }}
                      collapseOnDataChange={false}
                      className='-highlight'
                      SubComponent={row => {
                        return (
                          <ReactTable
                            className='-highlight'
                            defaultPageSize={row.original.regions.length}
                            data={row.original.regions}
                            SubComponent={rowSub => {
                              return (
                                <ReactTable
                                  className='-highlight'
                                  defaultPageSize={rowSub.original.facilities.length}
                                  data={rowSub.original.facilities}
                                  sortable={false}
                                  showPaginationBottom={false}
                                  defaultSorted={[{
                                    id: 'incidents',
                                    desc: true
                                  }]}
                                  defaultFilterMethod={(filter, row, column) => {
                                    const id = filter.pivotId || filter.id
                                    if (id === 'type') {
                                      if (filter.value.value === 'all') {
                                        return true
                                      } else {
                                        let filterValue = filter.value
                                        if (typeof filterValue === 'string') {
                                          filterValue = filter.value
                                            .replace('á', 'a')
                                            .replace('é', 'e')
                                            .replace('í', 'i')
                                            .replace('ó', 'o')
                                            .replace('ú', 'u')
                                        }
                                        let rowValue = row[filter.id]
                                        if (typeof rowValue === 'string') {
                                          rowValue = row[filter.id]
                                            .replace('á', 'a')
                                            .replace('é', 'e')
                                            .replace('í', 'i')
                                            .replace('ó', 'o')
                                            .replace('ú', 'u')
                                        }
                                        return new RegExp(filterValue, 'gi').test(
                                          String(rowValue)
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, '')
                                        )
                                      }
                                    } else {
                                      let filterValue = filter.value
                                      if (typeof filterValue === 'string') {
                                        filterValue = filter.value
                                          .replace('á', 'a')
                                          .replace('é', 'e')
                                          .replace('í', 'i')
                                          .replace('ó', 'o')
                                          .replace('ú', 'u')
                                      }
                                      let rowValue = row[filter.id]
                                      if (typeof rowValue === 'string') {
                                        rowValue = row[filter.id]
                                          .replace('á', 'a')
                                          .replace('é', 'e')
                                          .replace('í', 'i')
                                          .replace('ó', 'o')
                                          .replace('ú', 'u')
                                      }
                                      return new RegExp(filterValue, 'gi').test(
                                        String(rowValue)
                                          .normalize('NFD')
                                          .replace(/[\u0300-\u036f]/g, '')
                                      )
                                    }
                                  }}
                                  columns={[
                                    {
                                      Header: '',
                                      accessor: 'countryISO',
                                      width: 80,
                                      Cell: ({ row }) => {
                                        if (row.countryISO !== 'UNK') {
                                          return (
                                            <img style={{ marginLeft: 36 }} src={`https://flagcdn.com/20x15/${row.countryISO.toLowerCase()}.png`} />
                                          )
                                        } else {
                                          return null
                                        }
                                      }
                                    },
                                    {
                                      Header: 'Facility',
                                      accessor: 'facilityName',
                                      style: {
                                        fontWeight: 'bold'
                                      },
                                      width: 184
                                    },
                                    {
                                      Header: 'Incidents',
                                      accessor: 'incidents'
                                    },
                                    {
                                      Header: 'Non LTI',
                                      accessor: 'nonLTI',
                                      className: 'text-success'
                                    },
                                    {
                                      Header: 'LTI',
                                      accessor: 'LTI',
                                      className: 'text-warning'
                                    },
                                    {
                                      Header: 'Fatal Case',
                                      accessor: 'fatalCase',
                                      className: 'text-danger'
                                    },
                                    {
                                      Header: '% Total',
                                      accessor: 'total',
                                      className: 'text-right text-muted',
                                      style: {
                                        fontWeight: 'bold'
                                      }
                                    }
                                  ]}
                                />
                              )
                            }}
                            sortable={false}
                            showPaginationBottom={false}
                            defaultSorted={[{
                              id: 'incidents',
                              desc: true
                            }]}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id
                              if (id === 'type') {
                                if (filter.value.value === 'all') {
                                  return true
                                } else {
                                  let filterValue = filter.value
                                  if (typeof filterValue === 'string') {
                                    filterValue = filter.value
                                      .replace('á', 'a')
                                      .replace('é', 'e')
                                      .replace('í', 'i')
                                      .replace('ó', 'o')
                                      .replace('ú', 'u')
                                  }
                                  let rowValue = row[filter.id]
                                  if (typeof rowValue === 'string') {
                                    rowValue = row[filter.id]
                                      .replace('á', 'a')
                                      .replace('é', 'e')
                                      .replace('í', 'i')
                                      .replace('ó', 'o')
                                      .replace('ú', 'u')
                                  }
                                  return new RegExp(filterValue, 'gi').test(
                                    String(rowValue)
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                  )
                                }
                              } else {
                                let filterValue = filter.value
                                if (typeof filterValue === 'string') {
                                  filterValue = filter.value
                                    .replace('á', 'a')
                                    .replace('é', 'e')
                                    .replace('í', 'i')
                                    .replace('ó', 'o')
                                    .replace('ú', 'u')
                                }
                                let rowValue = row[filter.id]
                                if (typeof rowValue === 'string') {
                                  rowValue = row[filter.id]
                                    .replace('á', 'a')
                                    .replace('é', 'e')
                                    .replace('í', 'i')
                                    .replace('ó', 'o')
                                    .replace('ú', 'u')
                                }
                                return new RegExp(filterValue, 'gi').test(
                                  String(rowValue)
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                )
                              }
                            }}
                            columns={[
                              {
                                Header: '',
                                accessor: 'country',
                                width: 30,
                                Cell: ({ row }) => {
                                  return (
                                    <img src={`https://flagcdn.com/20x15/${row.country.toLowerCase()}.png`} />
                                  )
                                }
                              },
                              {
                                Header: 'Region',
                                accessor: 'country',
                                style: {
                                  fontWeight: 'bold'
                                },
                                width: 200,
                                Cell: ({ row }) => {
                                  return lookup.byIso(row.country).country
                                }
                              },
                              {
                                Header: 'Incidents',
                                accessor: 'incidents'
                              },
                              {
                                Header: 'Non LTI',
                                accessor: 'nonLTI',
                                className: 'text-success'
                              },
                              {
                                Header: 'LTI',
                                accessor: 'LTI',
                                className: 'text-warning'
                              },
                              {
                                Header: 'Fatal Case',
                                accessor: 'fatalCase',
                                className: 'text-danger'
                              },
                              {
                                Header: '% Total',
                                accessor: 'total',
                                className: 'text-right text-muted',
                                style: {
                                  fontWeight: 'bold'
                                }
                              }
                            ]}
                          />
                        )
                      }}
                      columns={[
                        {
                          Header: 'Area',
                          accessor: 'area',
                          width: 230,
                          style: {
                            fontWeight: 'bold'
                          }
                        },
                        {
                          Header: 'Incidents',
                          accessor: 'incidents',
                          style: {
                            fontWeight: 'bold'
                          }
                        },
                        {
                          Header: 'Non LTI',
                          accessor: 'nonLTI',
                          className: 'text-success',
                          style: {
                            fontWeight: 'bold'
                          }
                        },
                        {
                          Header: 'LTI',
                          accessor: 'LTI',
                          className: 'text-warning',
                          style: {
                            fontWeight: 'bold'
                          }
                        },
                        {
                          Header: 'Fatal Case',
                          accessor: 'fatalCase',
                          className: 'text-danger',
                          style: {
                            fontWeight: 'bold'
                          }
                        },
                        {
                          Header: '% Total',
                          accessor: 'total',
                          className: 'text-right text-muted',
                          style: {
                            fontWeight: 'bold'
                          }
                        }
                      ]}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card
              title='Incidents Category Risk'
              category='Total incidents reported by category risk'
              content={
                <ChartistGraph
                  data={getChartPieData()}
                  type='Pie'
                />
              }
              legend={
                <div>
                  <i className='fa fa-circle text-success' />
                  <span className='text-success'>Non-LTI</span> {' '}
                  <i className='fa fa-circle text-warning' />
                  <span className='text-warning'>LTI</span> {' '}
                  <i className='fa fa-circle text-danger' />
                  <span className='text-danger'>Fatal Case</span> {' '}
                </div>
              }
              stats={
                <div>
                  <i className='fa fa-refresh' /> Updated Now
                </div>
              }
            />
          </Col>
          <Col md={8}>
            <Card
              title={CURRENT_YEAR + ' Incidents'}
              category='All incidents reported on the current year'
              content={
                <ChartistGraph
                  data={getBarData()}
                  type='Bar'
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false
                    },
                    height: '245px'
                  }}
                  responsiveOptions={[
                    [
                      'screen and (max-width: 640px)',
                      {
                        seriesBarDistance: 5,
                        axisX: {
                          labelInterpolationFnc: function (value) {
                            return value[0]
                          }
                        }
                      }
                    ]
                  ]}
                />
              }
              legend={
                <div>
                  <i className='fa fa-circle text-success' />
                  <span className='text-success'>Non-LTI</span> {' '}
                  <i className='fa fa-circle text-warning' />
                  <span className='text-warning'>LTI</span> {' '}
                  <i className='fa fa-circle text-danger' />
                  <span className='text-danger'>Fatal Case</span> {' '}
                </div>
              }
              stats={
                <div>
                  <i className='fa fa-refresh' /> Updated Now
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

const styles = {
  dropdownContainer: {
    padding: '0px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}

export default Dashboard
