import React, { Component } from 'react'
import Select from 'react-select'

const themeStyles = theme => {
  return ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#eeeeee',
      primary50: '#b8b8b8',
      primary: '#585858'
    }
  })
}

class Dropdown extends Component {
  render () {
    const selectStyles = {
      container: (base, state) => {
        return {
          ...base,
          width: this.props.width || '100%',
          cursor: state.isDisabled ? 'not-allowed !important' : 'default',
          pointerEvents: 'all'
        }
      },
      control: (base, state) => {
        return {
          ...base,
          minHeight: 30,
          borderRadius: 0,
          border: '0 !important',
          boxShadow: '0 !important',
          borderBottom: '2px solid #02243C !important',
          fontSize: '16px',
          pointerEvents: state.isDisabled ? 'none' : 'all',
          backgroundColor: state.isDisabled ? '#E3E3E3' : 'initial'
        }
      },
      dropdownIndicator: base => ({
        ...base,
        color: '#02243C',
        padding: 4
      }),
      indicatorSeparator: base => ({
        ...base,
        backgroundColor: 'transparent'
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4
      }),
      menuPortal: base => ({
        ...base,
        zIndex: 9999
      }),
      valueContainer: base => ({
        ...base,
        padding: '0px 6px'
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0
      }),
      multiValue: (base, state) => {
        return { ...base, backgroundColor: '#585858' }
      },
      multiValueLabel: (base, state) => {
        return { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      },
      multiValueRemove: (base, state) => {
        return { ...base, fontWeight: 'bold', color: 'white' }
      },
      menu: (base, state) => {
        return { ...base, textAlign: 'left' }
      },
      option: (base, state) => {
        return { ...base, whiteSpace: 'pre-wrap' }
      }
    }
    return (
      <Select
        id='scene'
        styles={selectStyles}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        theme={theme => themeStyles(theme)}
        {...this.props}
      />
    )
  }
}

export default Dropdown
