import React, { useEffect, useState } from 'react'
// Firebase
import firebase from 'config/firebase'
// Bootstrap
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert
} from 'react-bootstrap'
// Components
import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'

const LoginPage = props => {
  const [showError, setShowError] = useState(false)
  const [cardHidden, setCardHidden] = useState(true)

  useEffect(() => {
    setTimeout(
      function () {
        setCardHidden(false)
      },
      700
    )
  }, [])

  return (
    <>
      <Grid>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            const { email, password } = values
            try {
              await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
              props.history.push('/admin/dashboard')
            } catch (error) {
              console.log('Login error', error)
              setShowError(error.message)
              setTimeout(() => {
                setShowError(false)
              }, 5000)
            }
            setSubmitting(false)
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required('Email es obligatorio'),
            password: Yup.string()
              .required('Password es obligatorio')
              .min(6, 'Password demasiado corto')
          })}
        >

          {formikProps => {
            const {
              values,
              dirty,
              isValid,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = formikProps
            return (
              <Row>
                <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                  {showError && (
                    <Alert
                      style={{
                        position: 'absolute',
                        top: -50,
                        width: '92%'
                      }}
                      bsStyle='danger'
                    >
                      <strong>Error {' '}</strong>{showError}
                    </Alert>
                  )}
                  <form onSubmit={handleSubmit}>
                    <Card
                      hidden={cardHidden}
                      textCenter
                      title='Login'
                      content={
                        <div>
                          <FormGroup>
                            <ControlLabel>Email address</ControlLabel>
                            <FormControl
                              placeholder='Enter email'
                              type='email'
                              id='email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                          <FormGroup>
                            <ControlLabel>Password</ControlLabel>
                            <FormControl
                              placeholder='Password'
                              type='password'
                              autoComplete='off'
                              id='password'
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                          <FormGroup className='text-right'>
                            <a
                              className='text-primary pointer'
                              onClick={() => props.history.push('/auth/forgot-password')}
                            >
                              Forgot Password?
                            </a>
                          </FormGroup>
                        </div>
                      }
                      legend={
                        <Button
                          type='submit'
                          onClick={handleSubmit}
                          disabled={!dirty || isSubmitting || !isValid}
                          bsStyle='info'
                          fill
                          wd
                        >
                          Login
                        </Button>
                      }
                      ftTextCenter
                    />
                  </form>
                </Col>
              </Row>
            )
          }}
        </Formik>
      </Grid>
    </>
  )
}

export default LoginPage
