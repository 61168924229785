import React from 'react'
// Images
import logo from 'assets/img/maersk-star.svg'

export default function Loader () {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <img className='animate-logo' width='100' alt='logo' src={logo} />
    </div>
  )
}
