/*!

=========================================================
* HSSE Maersk Landside Services
=========================================================

* Estudio Cactus Media S.L. (https://estudiocactus.com)

=========================================================

*/
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0'
import 'assets/css/demo.css'
import 'assets/css/pe-icon-7-stroke.css'

import AuthLayout from 'layouts/Auth.jsx'
import AdminLayout from 'layouts/Admin.jsx'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/auth' render={props => <AuthLayout {...props} />} />
      <Route path='/admin' render={props => <AdminLayout {...props} />} />
      <Redirect from='/' to='/admin/dashboard' />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
)
