import React, { useState, useEffect, useContext } from 'react'
// Context
import UserContext from 'context/UserContext'
// Table
import ReactTable from 'react-table'
// Firebase
import firebase from 'config/firebase'
// Bootstrap
import { Grid, Row, Col, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap'
// Components
import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
// Dates
import { format } from 'date-fns'
// CSV
import { CSVLink } from 'react-csv'

export const Incidents = props => {
  const context = useContext(UserContext)
  const [data, setData] = useState([])

  useEffect(() => {
    let incidentsRef = firebase.firestore()
      .collection('incidents')

    if (context.user.role === 'admin') {
      incidentsRef = incidentsRef.where('countryISO', 'in', context.user?.regions)
    }

    incidentsRef.onSnapshot(snapshot => {
      if (snapshot.docs.length > 0) {
        setData(snapshot.docs.map(incident => {
          return {
            id: incident.id,
            ...incident.data()
          }
        }))
      } else {
        setData([])
      }
    })
  }, [])

  const placeHolder = ({ filter, onChange }) => (
    <input
      type='text'
      style={{ width: '100%' }}
      placeholder='Search...'
      value={filter ? filter.value : ''}
      onChange={event => onChange(event.target.value)}
    />
  )

  const getBadge = (id) => {
    if (
      context.notifications.filter(
        notification => notification.incidentId === id
      ).length > 0
    ) {
      return (
        <Badge
          className='badge-danger'
        >
          {
              context.notifications.filter(
                notification => notification.incidentId === id
              ).length
            }
        </Badge>
      )
    } else {
      return null
    }
  }

  return (
    <div className='main-content'>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <ReactTable
                  previousText='Previous'
                  nextText='Next'
                  loadingText='Loading...'
                  noDataText='No incidents'
                  pageText='Page'
                  ofText='of'
                  rowsText='Incidents'
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id
                    if (id === 'type') {
                      if (filter.value.value === 'all') {
                        return true
                      } else {
                        let filterValue = filter.value
                        if (typeof filterValue === 'string') {
                          filterValue = filter.value
                            .replace('á', 'a')
                            .replace('é', 'e')
                            .replace('í', 'i')
                            .replace('ó', 'o')
                            .replace('ú', 'u')
                        }
                        let rowValue = row[filter.id]
                        if (typeof rowValue === 'string') {
                          rowValue = row[filter.id]
                            .replace('á', 'a')
                            .replace('é', 'e')
                            .replace('í', 'i')
                            .replace('ó', 'o')
                            .replace('ú', 'u')
                        }
                        return new RegExp(filterValue, 'gi').test(
                          String(rowValue)
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                        )
                      }
                    } else {
                      let filterValue = filter.value
                      if (typeof filterValue === 'string') {
                        filterValue = filter.value
                          .replace('á', 'a')
                          .replace('é', 'e')
                          .replace('í', 'i')
                          .replace('ó', 'o')
                          .replace('ú', 'u')
                      }
                      let rowValue = row[filter.id]
                      if (typeof rowValue === 'string') {
                        rowValue = row[filter.id]
                          .replace('á', 'a')
                          .replace('é', 'e')
                          .replace('í', 'i')
                          .replace('ó', 'o')
                          .replace('ú', 'u')
                      }
                      return new RegExp(filterValue, 'gi').test(
                        String(rowValue)
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                      )
                    }
                  }}
                  columns={[
                    {
                      Header: '',
                      accessor: 'incidentId',
                      headerClassName: 'question-input',
                      Filter: placeHolder,
                      filterable: false,
                      sortable: false,
                      width: 30,
                      className: 'text-muted',
                      style: {
                        fontWeight: 'bold'
                      },
                      Cell: ({ row }) => {
                        return (
                          <div style={{ position: 'relative' }}>
                            {getBadge(row._original.id)}
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'ID',
                      accessor: 'incidentId',
                      headerClassName: 'question-input',
                      Filter: placeHolder,
                      width: 70,
                      className: 'text-muted',
                      style: {
                        fontWeight: 'bold'
                      },
                      Cell: ({ row }) => {
                        return (
                          <>
                            # {row.incidentId}
                          </>
                        )
                      }
                    },
                    {
                      Header: 'Created',
                      accessor: 'createdAt',
                      headerClassName: 'question-input',
                      Filter: placeHolder,
                      width: 160,
                      Cell: ({ row }) => {
                        const date = format(row.createdAt.toDate(), 'dd/MM/yyyy HH:mm')
                        return date
                      },
                      style: {
                        fontFamily: 'Monospace'
                      }
                    },
                    {
                      Header: 'Passport',
                      accessor: 'createdBy.passport',
                      headerClassName: 'question-input',
                      Filter: placeHolder,
                      width: 140,
                      style: {
                        fontWeight: 'bold',
                        letterSpacing: '1px'
                      }
                    },
                    {
                      Header: 'Fullname',
                      accessor: 'createdBy.fullName',
                      headerClassName: 'question-input',
                      Filter: placeHolder
                    },
                    {
                      Header: 'Company',
                      accessor: 'createdBy.company',
                      headerClassName: 'question-input',
                      Filter: placeHolder
                    },
                    {
                      Header: 'Container',
                      accessor: 'container',
                      headerClassName: 'question-input',
                      Filter: placeHolder,
                      className: 'text-primary',
                      style: {
                        fontWeight: 'bold'
                      }
                    },
                    {
                      Header: 'Risk',
                      accessor: 'container',
                      headerClassName: 'question-input',
                      Cell: ({ row }) => {
                        if (row._original.someoneInjured && row._original.injureOrFatality === 'fatality') {
                          return (
                            <>
                              <i className='fa fa-circle text-danger' />
                              <b className='text-danger'>Fatal Case</b>
                            </>
                          )
                        } else if (row._original.someoneInjured && row._original.injureOrFatality === 'injury') {
                          return (
                            <>
                              <i className='fa fa-circle text-warning' />
                              <b className='text-warning'>LTI</b>
                            </>
                          )
                        } else {
                          return (
                            <>
                              <i className='fa fa-circle text-success' />
                              <b className='text-success'>Non-LTI</b>
                            </>
                          )
                        }
                      }
                    },
                    {
                      Header: 'Photos',
                      width: 70,
                      accessor: 'images',
                      filterable: false,
                      className: 'text-muted',
                      Cell: ({ row }) => {
                        if (row.images.length > 0) {
                          return 'Yes'
                        } else {
                          return 'No'
                        }
                      }
                    },
                    {
                      Header: 'Location',
                      width: 80,
                      accessor: 'location',
                      filterable: false,
                      className: 'text-muted',
                      Cell: ({ row }) => {
                        if (row.location) {
                          return 'Yes'
                        } else {
                          return 'No'
                        }
                      }
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <div className='actions-right'>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                (
                                  <Tooltip id='tooltip'>
                                    User Info
                                  </Tooltip>
                                )
                              }
                            >
                              <Button
                                onClick={() => {
                                  props.history.push(`/admin/user/${row._original.createdBy.uid}/info`)
                                }}
                                bsStyle='primary'
                                simple
                                icon
                              >
                                <i className='fa fa-user' />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                (
                                  <Tooltip id='tooltip'>
                                    Incident Details
                                  </Tooltip>
                                )
                              }
                            >
                              <Button
                                onClick={() => {
                                  props.history.push(`/admin/user/${row._original.createdBy.uid}/incidents/${row._original.id}/info`)
                                }}
                                bsStyle='warning'
                                simple
                                icon
                              >
                                <i className='fa fa-warning' />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        )
                      }
                    }
                  ]}
                  defaultSorted={[{
                    id: 'createdAt',
                    desc: true
                  }]}
                  defaultPageSize={20}
                  showPaginationBottom
                  className='-striped -highlight'
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CSVLink
              separator=';'
              filename='incidents.csv'
              style={{ float: 'right', marginRight: 20 }}
              data={data}
            >
              DOWNLOAD CSV
            </CSVLink>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}
