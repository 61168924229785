import React, { useContext, useState, useRef, useEffect } from 'react'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'config/firebase'
// Components
import Button from 'components/CustomButton/CustomButton'
import { FeedbackMessage } from './FeedbackMessage'
// Bootstrap
import { FormControl } from 'react-bootstrap'

export const UserIncidentsFeedback = ({ incidentId, user }) => {
  const authContext = useContext(UserContext)
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState()
  const scroll = useRef(null)

  const checkForNotificationsToDelete = () => {
    const notifications = authContext.notifications.filter(notification => notification.incidentId === incidentId && notification.topTab === 'feedback')
    if (notifications.length > 0) {
      notifications.forEach(n => {
        firebase.firestore()
          .collection('admins')
          .doc(authContext.user.id)
          .collection('notifications')
          .doc(n.id)
          .delete()
      })
    }
  }

  useEffect(() => {
    checkForNotificationsToDelete()
    firebase.firestore()
      .collection('incidents')
      .doc(incidentId)
      .collection('feedback')
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        if (snapshot.docs.length > 0) {
          setMessages(snapshot.docs.map(message => {
            return {
              id: message.id,
              ...message.data()
            }
          }))
        } else {
          setMessages([])
        }
      })
  }, [incidentId])

  useEffect(() => {
    if (messages.length > 0) {
      scroll.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }, [messages])

  useEffect(() => {
    checkForNotificationsToDelete()
  }, [authContext.notifications])

  const sendFeedback = () => {
    if (message) {
      firebase.firestore()
        .collection('incidents')
        .doc(incidentId)
        .collection('feedback')
        .add({
          createdAt: new Date(),
          message,
          createdBy: {
            uid: 'maersk',
            fullName: 'Maersk Support'
          }
        }).then((docRef) => {
          setMessage('')
        })
    }
  }

  return (
    <div style={styles.container}>
      <div ref={scroll} style={styles.messageContainer}>
        {messages.map(message => (
          <FeedbackMessage
            key={message.id}
            item={message}
          />
        ))}
      </div>
      <div style={styles.inputContainer}>
        <FormControl
          onKeyDown={e => {
            if (e.key === 'Enter') {
              sendFeedback()
            }
          }}
          placeholder='Message...'
          type='email'
          id='email'
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <Button
          onClick={() => sendFeedback()}
          bsStyle='primary'
          bsSize='lg'
          simple wd
        >
          <i className='fa fa-paper-plane text-info' />
        </Button>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  messageContainer: {
    padding: 20,
    flex: 1,
    overflow: 'auto',
    paddingBottom: 100
  },
  inputContainer: {
    borderTop: '1px solid #E7E7E7',
    backgroundColor: '#FAFAFA',
    padding: '20px 10px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex'
  }
}
