import React, { useState, useEffect, useContext } from 'react'
// Context
import UserContext from 'context/UserContext'
// Table
import ReactTable from 'react-table'
// Firebase
import firebase from 'config/firebase'
// Dates
import { format, compareAsc } from 'date-fns'

export const UserInductions = ({ userId }) => {
  const context = useContext(UserContext)
  const [data, setData] = useState([])

  useEffect(() => {
    firebase.firestore()
      .collection('inductions')
      .where('user.uid', '==', userId)
      .onSnapshot(snapshot => {
        if (snapshot.docs.length > 0) {
          setData(snapshot.docs.map(induction => {
            return {
              id: induction.id,
              ...induction.data()
            }
          }))
        } else {
          setData()
        }
      })
  }, [])

  return (
    <div>
      <ReactTable
        previousText='Previous'
        nextText='Next'
        loadingText='Loading...'
        noDataText='No inductions'
        pageText='Page'
        ofText='of'
        rowsText='Inductions'
        data={data}
        filterable
        defaultFilterMethod={(filter, row, column) => {
          const id = filter.pivotId || filter.id
          if (id === 'type') {
            if (filter.value.value === 'all') {
              return true
            } else {
              let filterValue = filter.value
              if (typeof filterValue === 'string') {
                filterValue = filter.value
                  .replace('á', 'a')
                  .replace('é', 'e')
                  .replace('í', 'i')
                  .replace('ó', 'o')
                  .replace('ú', 'u')
              }
              let rowValue = row[filter.id]
              if (typeof rowValue === 'string') {
                rowValue = row[filter.id]
                  .replace('á', 'a')
                  .replace('é', 'e')
                  .replace('í', 'i')
                  .replace('ó', 'o')
                  .replace('ú', 'u')
              }
              return new RegExp(filterValue, 'gi').test(
                String(rowValue)
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              )
            }
          } else {
            let filterValue = filter.value
            if (typeof filterValue === 'string') {
              filterValue = filter.value
                .replace('á', 'a')
                .replace('é', 'e')
                .replace('í', 'i')
                .replace('ó', 'o')
                .replace('ú', 'u')
            }
            let rowValue = row[filter.id]
            if (typeof rowValue === 'string') {
              rowValue = row[filter.id]
                .replace('á', 'a')
                .replace('é', 'e')
                .replace('í', 'i')
                .replace('ó', 'o')
                .replace('ú', 'u')
            }
            return new RegExp(filterValue, 'gi').test(
              String(rowValue)
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
          }
        }}
        columns={[
          {
            Header: 'Created',
            accessor: 'createdAt',
            width: 160,
            Cell: ({ row }) => {
              const date = format(row.createdAt.toDate(), 'dd/MM/yyyy HH:mm')
              return date
            },
            style: {
              fontFamily: 'Monospace'
            }
          },
          {
            Header: 'Facility',
            accessor: 'facility',
            style: {
              fontWeight: 'bold',
              letterSpacing: '1px'
            },
            Cell: ({ row }) => {
              return context.facilities.filter(item => item.id === row.facility)[0]?.name || ''
            }
          },
          {
            Header: 'Expiry Date',
            accessor: 'expiryDate',
            width: 160,
            Cell: ({ row }) => {
              const date = format(row.createdAt.toDate(), 'dd/MM/yyyy HH:mm')
              return date
            },
            style: {
              fontFamily: 'Monospace'
            }
          },
          {
            Header: 'Status',
            accessor: 'expiryDate',
            style: {
              textAlign: 'right'
            },
            Cell: ({ row }) => {
              if (compareAsc(row.expiryDate.toDate(), new Date()) >= 0) {
                return (
                  <>
                    <i className='fa fa-circle text-success' />
                    <b className='text-success'>{' '}Granted</b>
                  </>
                )
              } else {
                return (
                  <>
                    <i className='fa fa-circle text-danger' />
                    <b className='text-danger'>{' '}Access Danied</b>
                  </>
                )
              }
            }
          }
        ]}
        defaultSorted={[{
          id: 'createdAt',
          desc: true
        }]}
        defaultPageSize={10}
        showPaginationBottom
        className='-striped -highlight'
      />
    </div>
  )
}
