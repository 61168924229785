import React, { useContext, useState } from 'react'
// Context
import UserContext from 'context/UserContext'
// Router
import {
  useHistory
} from 'react-router-dom'
// Firebase
import firebase from 'config/firebase'
// Bootstrap
import {
  Navbar,
  Nav,
  NavDropdown,
  MenuItem,
  FormGroup,
  FormControl,
  InputGroup,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
// Components
import Button from 'components/CustomButton/CustomButton.jsx'

let allUsers = null
let allIncidents = null
const HeaderLinks = () => {
  const history = useHistory()
  const context = useContext(UserContext)
  const [results, setResults] = useState([])
  const [searching, setSearching] = useState(false)
  const [inputValue, setInputValue] = useState()

  const deleteAllNotifications = () => {
    context.notifications.forEach(n => {
      firebase.firestore()
        .collection('admins')
        .doc(context.user.id)
        .collection('notifications')
        .doc(n.id)
        .delete()
    })
  }

  const searchFilter = value => {
    setInputValue(value)
    if (value) {
      if (value.charAt(0) === '#') {
        if (!allIncidents) {
          setSearching(true)

          let incidentsRef = firebase.firestore()
            .collection('incidents')

          if (context.user.role === 'admin') {
            incidentsRef = incidentsRef.where('countryISO', 'in', context.user?.regions)
          }

          incidentsRef.get()
            .then(snapshot => {
              setSearching(false)
              if (snapshot.docs.length > 0) {
                allIncidents = snapshot.docs.map(incident => {
                  return {
                    type: 'incident',
                    userId: incident.data().createdBy.uid,
                    id: incident.id,
                    title: 'Incident: #' + incident.data().incidentId,
                    subtitle: 'Container: ' + incident.data().container
                  }
                })
              } else {
                allIncidents = []
              }
              setResults(allIncidents.filter(item => {
                return item.title.toString().includes(value)
              }))
            })
        } else {
          setResults(allIncidents.filter(item => {
            return item.title.toString().includes(value)
          }))
        }
      } else {
        if (!allUsers) {
          setSearching(true)
          firebase.firestore()
            .collection('users')
            .get()
            .then(snapshot => {
              setSearching(false)
              if (snapshot.docs.length > 0) {
                allUsers = snapshot.docs.map(user => {
                  return {
                    type: 'user',
                    id: user.id,
                    title: user.data().fullName,
                    subtitle: user.data().passport
                  }
                })
              } else {
                allUsers = []
              }
              setResults(allUsers.filter(item => {
                return item.title.toLowerCase().includes(value.toLowerCase()) ||
                  item.subtitle.toLowerCase().includes(value.toLowerCase())
              }))
            })
        } else {
          setResults(allUsers.filter(item => {
            return item.title.toLowerCase().includes(value.toLowerCase()) ||
              item.subtitle.toLowerCase().includes(value.toLowerCase())
          }))
        }
      }
    } else {
      setResults([])
    }
  }

  const getNotificationRedirect = notification => {
    console.log(notification)
    if (notification.tab === 'incidents' && notification.userTab === 'incidents' && notification.topTab === 'feedback') {
      history.push(`/admin/user/${notification.data.createdBy.uid}/incidents/${notification.incidentId}/feedback/${notification.data.feedbackId}`)
    } else if (notification.tab === 'incidents' && notification.userTab === 'incidents' && notification.topTab === 'info') {
      history.push(`/admin/user/${notification.data.createdBy.uid}/incidents/${notification.incidentId}/info`)
    } else {
      return null
    }
  }

  const getNotificationTitle = notification => {
    if (notification.tab === 'incidents' && notification.userTab === 'incidents' && notification.topTab === 'feedback') {
      return 'New incident feedback message'
    } else if (notification.tab === 'incidents' && notification.userTab === 'incidents' && notification.topTab === 'info') {
      return 'New incident'
    } else {
      return null
    }
  }

  const getNotificationSubtitle = notification => {
    if (notification.tab === 'incidents' && notification.userTab === 'incidents' && notification.topTab === 'feedback') {
      return (
        <h6>
          <span className='text-muted'>{notification.data.createdBy.fullName}</span>{' '}
          <span
            className='text-muted'
            style={{
              textTransform: 'initial',
              fontWeight: 500,
              overflow: 'hidden',
              display: 'block',
              textOverflow: 'ellipsis',
              marginTop: 4
            }}
          >"{notification.data.message}"
          </span>
        </h6>
      )
    } else if (notification.tab === 'incidents' && notification.userTab === 'incidents' && notification.topTab === 'info') {
      return (
        <h6>
          <span className='text-muted'>{notification.data.createdBy.fullName}</span>{' '}
          <span
            className='text-muted'
            style={{
              textTransform: 'initial',
              fontWeight: 500,
              overflow: 'hidden',
              display: 'block',
              textOverflow: 'ellipsis',
              marginTop: 4
            }}
          >New incident reported
          </span>
        </h6>
      )
    } else {
      return null
    }
  }

  return (
    <div>
      <Navbar.Form pullLeft className='navbar-search-form'>
        <FormGroup>
          <InputGroup style={{ width: 300 }}>
            <InputGroup.Addon>
              <i className='fa fa-search' />
            </InputGroup.Addon>
            <FormControl
              value={inputValue}
              onChange={e => searchFilter(e.target.value)}
              onClick={() => context.setShowOverlay(true)}
              type='text' placeholder='Search passport/name or #incidence...'
            />
          </InputGroup>
        </FormGroup>
        {context.showOverlay && (
          <div
            style={styles.searchBox}
          >
            {searching && (
              <h6 className='text-muted'>Searching...</h6>
            )}
            {(results.length === 0 && !searching) && (
              <h6 className='text-muted'>No results found</h6>
            )}
            {results.map(result => {
              return (
                <div style={styles.item} key={result.id}>
                  <div
                    onClick={() => {
                      setInputValue('')
                      setResults([])
                      context.setShowOverlay(false)
                      if (result.type === 'incident') {
                        history.push(`/admin/user/${result.userId}/incidents/${result.id}/info`)
                      } else {
                        history.push(`/admin/user/${result.userId}/info`)
                      }
                    }} style={styles.titleBox}
                  >
                    <h6 style={styles.title}>
                      {result.title}
                    </h6>
                    <h6 style={styles.subtitle}>
                      <small>
                        {result.subtitle}
                      </small>
                    </h6>
                  </div>
                  {result.type === 'incident' && (
                    <div style={styles.actionsBox}>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          (
                            <Tooltip id='tooltip'>
                              User Info
                            </Tooltip>
                          )
                        }
                      >
                        <Button
                          onClick={() => {
                            setInputValue('')
                            setResults([])
                            context.setShowOverlay(false)
                            history.push(`/admin/user/${result.userId}/info`)
                          }}
                          bsStyle='primary'
                          simple
                          icon
                        >
                          <i className='fa fa-user' />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          (
                            <Tooltip id='tooltip'>
                              Incident Details
                            </Tooltip>
                          )
                        }
                      >
                        <Button
                          onClick={() => {
                            setInputValue('')
                            setResults([])
                            context.setShowOverlay(false)
                            history.push(`/admin/user/${result.userId}/incidents/${result.id}/info`)
                          }}
                          bsStyle='warning'
                          simple
                          icon
                        >
                          <i className='fa fa-warning' />
                        </Button>
                      </OverlayTrigger>
                    </div>

                  )}
                  {result.type === 'user' && (
                    <div style={styles.actionsBox}>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          (
                            <Tooltip id='tooltip'>
                              Info
                            </Tooltip>
                          )
                        }
                      >
                        <Button
                          onClick={() => {
                            setInputValue('')
                            setResults([])
                            context.setShowOverlay(false)
                            history.push(`/admin/user/${result.id}/info`)
                          }}
                          bsStyle='primary'
                          simple
                          icon
                        >
                          <i className='fa fa-info' />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          (
                            <Tooltip id='tooltip'>
                              Inductions
                            </Tooltip>
                          )
                        }
                      >
                        <Button
                          onClick={() => {
                            setInputValue('')
                            setResults([])
                            context.setShowOverlay(false)
                            history.push(`/admin/user/${result.id}/inductions`)
                          }}
                          bsStyle='success'
                          simple
                          icon
                        >
                          <i className='fa fa-list-ul' />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          (
                            <Tooltip id='tooltip'>
                              Incidents
                            </Tooltip>
                          )
                        }
                      >
                        <Button
                          onClick={() => {
                            setInputValue('')
                            setResults([])
                            context.setShowOverlay(false)
                            history.push(`/admin/user/${result.id}/incidents`)
                          }}
                          bsStyle='warning'
                          simple
                          icon
                        >
                          <i className='fa fa-warning' />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </Navbar.Form>
      <Nav pullRight>
        <NavDropdown
          className='dropdown-notifications'
          title={
            <div>
              <i className='fa fa-bell-o' />
              {context.notifications.length > 0 && (
                <span className='notification'>{context.notifications.length}</span>
              )}
              <p className='hidden-md hidden-lg'>
                Notifications
                <b className='caret' />
              </p>
            </div>
          }
          noCaret
        >
          {context.notifications.length === 0 && (
            <MenuItem>
              <h6 className='text-muted'>No notifications</h6>
            </MenuItem>
          )}
          {context.notifications.map(notification => (
            <MenuItem
              onClick={() => getNotificationRedirect(notification)}
              style={{ minWidth: 400, maxWidth: 600 }}
              key={notification.id}
            >
              <h6
                className='text-primary'
              >
                {getNotificationTitle(notification)}
              </h6>
              {getNotificationSubtitle(notification)}
              <div style={{ height: 2, background: '#f5f5f5', width: 60 }} />
            </MenuItem>
          ))}
          {context.notifications.length !== 0 && (
            <MenuItem divider />
          )}
          {context.notifications.length !== 0 && (
            <MenuItem className='text-center' onClick={() => deleteAllNotifications()}>
              <p className='text-muted'>Mark all as read</p>
            </MenuItem>
          )}
        </NavDropdown>
      </Nav>
    </div>
  )
}

const styles = {
  searchBox: {
    overflow: 'auto',
    padding: 16,
    zIndex: 9,
    position: 'absolute',
    background: 'white',
    width: 380,
    height: 200,
    boxShadow: '0 8px 6px 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 4
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #efefef',
    justifyContent: 'center',
    alignItems: 'baseline'
  },
  title: {
    margin: 0
  },
  subtitle: {
    margin: 0
  },
  titleBox: {
    cursor: 'pointer',
    flex: 1
  },
  actionBox: {

  }
}

export default HeaderLinks
