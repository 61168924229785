import React, { useEffect, useState } from 'react'
// Firebase
import firebase from 'config/firebase'
// Bootstrap
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert
} from 'react-bootstrap'
// Components
import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'

const LoginPage = props => {
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [cardHidden, setCardHidden] = useState(true)

  useEffect(() => {
    setTimeout(
      function () {
        setCardHidden(false)
      },
      700
    )
  }, [])

  return (
    <>
      <Grid>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            firebase.auth().sendPasswordResetEmail(values.email)
              .then(() => {
                setSubmitting(false)
                setShowSuccess('Email send. Please check your inbox.')
                setTimeout(() => {
                  setShowSuccess(false)
                }, 4000)
              }).catch(err => {
                setSubmitting(false)
                console.log(err)
                setShowError(err.message)
                setTimeout(() => {
                  setShowError(false)
                }, 4000)
              })
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required('Email es obligatorio')
          })}
        >

          {formikProps => {
            const {
              values,
              dirty,
              isValid,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = formikProps
            return (
              <Row>
                <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                  {showError && (
                    <Alert
                      style={{
                        position: 'absolute',
                        top: -80,
                        width: '92%'
                      }}
                      bsStyle='danger'
                    >
                      <strong>Error {' '}</strong>{showError}
                    </Alert>
                  )}
                  {showSuccess && (
                    <Alert
                      style={{
                        position: 'absolute',
                        top: -80,
                        width: '92%'
                      }}
                      bsStyle='success'
                    >
                      <strong>Success {' '}</strong>{showSuccess}
                    </Alert>
                  )}
                  <form onSubmit={handleSubmit}>
                    <Card
                      hidden={cardHidden}
                      textCenter
                      title='Forgot Password'
                      content={
                        <div>
                          <FormGroup>
                            <ControlLabel>Email address</ControlLabel>
                            <FormControl
                              placeholder='Enter email'
                              type='email'
                              id='email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                          <FormGroup className='text-right'>
                            <span className='text-muted'>Have an account? {' '}</span>
                            <a
                              className='text-primary pointer'
                              onClick={() => props.history.push('/auth/login')}
                            >
                              Login
                            </a>
                          </FormGroup>
                        </div>
                      }
                      legend={
                        <Button
                          type='submit'
                          onClick={handleSubmit}
                          disabled={!dirty || isSubmitting || !isValid}
                          bsStyle='info'
                          fill
                          wd
                        >
                          Reset password
                        </Button>
                      }
                      ftTextCenter
                    />
                  </form>
                </Col>
              </Row>
            )
          }}
        </Formik>
      </Grid>
    </>
  )
}

export default LoginPage
