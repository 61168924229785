import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const config = {
  apiKey: 'AIzaSyCNi995p1Ah6XntOj6pGZltrk8keTSJcAk',
  authDomain: 'hsse-maersk-inland-services.firebaseapp.com',
  databaseURL: 'https://hsse-maersk-inland-services.firebaseio.com',
  projectId: 'hsse-maersk-inland-services',
  storageBucket: 'hsse-maersk-inland-services.appspot.com',
  messagingSenderId: '1090142519209',
  appId: '1:1090142519209:web:0ddc7f25fa77f356f4ceb8'
}

const googleAuthProvider = new fb.auth.GoogleAuthProvider()
googleAuthProvider.setCustomParameters({
  prompt: 'select_account'
})
const firebase = fb.initializeApp(config)

export default firebase
export { googleAuthProvider }
