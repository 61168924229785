import React from 'react'
// Components
import UserCard from 'components/Card/UserCard.jsx'
// Bootstrap
import { Grid, Row, Col } from 'react-bootstrap'
// Config
import { ROLES } from 'config/roles'
// Date
import { format } from 'date-fns'

import avatar from 'assets/img/default-avatar.png'

export const UserInfo = ({ user }) => {
  return (
    <Grid fluid>
      <Row>
        <Col md={4} mdOffset={4}>
          <UserCard
            bgImage={require('assets/img/bg-user.png')}
            avatar={avatar}
            name={user?.fullName}
            userName={user?.passport}
            description={
              <div style={{ marginTop: '20px' }}>
                <h6>{user?.email}</h6>
                <p className='text-primary'>{user?.company}</p>
                <h5>
                  <small>
                    <span className='text-muted'>Created at: {' '}
                      <b>
                        {user?.createdAt && format(user?.createdAt.toDate(), 'dd/MM/yyyy HH:mm')}
                      </b>
                    </span>
                  </small>
                </h5>
                <hr />
                <br />
                <h5 className='text-info'><b>{ROLES[user?.type]?.name}</b></h5>
              </div>
                }
          />
        </Col>
      </Row>
    </Grid>

  )
}
