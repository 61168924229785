export const AREAS = {
  centralMediterranean: {
    name: 'Central Mediterranean',
    slug: 'CM',
    regions: ['IT']
  },
  easternEurope: {
    name: 'Eastern Europe',
    slug: 'EU',
    regions: ['RO']
  },
  easternMediterranean: {
    name: 'Eastern Mediterranean',
    slug: 'EM',
    regions: ['GR']
  },
  northWestContinent: {
    name: 'North West Continent',
    slug: 'NWC',
    regions: ['DE', 'CH']
  },
  scandinavia: {
    name: 'Scandinavia',
    slug: 'S',
    regions: ['SE', 'NO', 'DK', 'IS', 'FI']
  },
  southWestEurope: {
    name: 'South West Europe',
    slug: 'S',
    regions: ['FR', 'ES', 'PT', 'MA', 'DZ', 'TN']
  },
  unitedKingdomAndIreland: {
    name: 'United Kingdom and Ireland',
    slug: 'UK',
    regions: ['GB', 'IE']
  }
}
