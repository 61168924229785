export const LANGUAGES = {
  EN: {
    name: 'English',
    flag: 'gb'
  },
  ES: {
    name: 'Español',
    flag: 'es'
  },
  IT: {
    name: 'Italiano',
    flag: 'it'
  },
  FR: {
    name: 'Français',
    flag: 'fr'
  },
  PT: {
    name: 'Portugues',
    flag: 'pt'
  },
  AR: {
    name: 'Arabic',
    flag: 'sa'
  }
}
