import React, { useState, useEffect, useContext } from 'react'
// Context
import UserContext from 'context/UserContext'
// Table
import ReactTable from 'react-table'
// Firebase
import firebase from 'config/firebase'
// Bootstrap
import { Grid, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
// Components
import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
// Config
import { ROLES } from 'config/roles'
// Dates
import { format } from 'date-fns'
// CSV
import { CSVLink } from 'react-csv'

export const Inductions = props => {
  const context = useContext(UserContext)
  const [data, setData] = useState([])

  useEffect(() => {
    if (context.facilities.length > 0) {
      let inductionsRef = firebase.firestore()
        .collection('inductions')
      if (context.user.role === 'admin') {
        inductionsRef = inductionsRef.where('countryISO', 'in', context.user?.regions)
      }
      inductionsRef.onSnapshot(snapshot => {
        if (snapshot.docs.length > 0) {
          setData(snapshot.docs.map(item => {
            return {
              id: item.id,
              ...item.data()
            }
          }))
        } else {
          setData([])
        }
      })
    }
  }, [context.facilities])

  const placeHolder = ({ filter, onChange }) => (
    <input
      type='text'
      style={{ width: '100%' }}
      placeholder='Search...'
      value={filter ? filter.value : ''}
      onChange={event => onChange(event.target.value)}
    />
  )

  return (
    <div className='main-content'>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <ReactTable
                  previousText='Previous'
                  nextText='Next'
                  loadingText='Loading...'
                  noDataText='No users'
                  pageText='Page'
                  ofText='of'
                  rowsText='Users'
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id
                    if (id === 'type') {
                      if (filter.value.value === 'all') {
                        return true
                      } else {
                        let filterValue = filter.value
                        if (typeof filterValue === 'string') {
                          filterValue = filter.value
                            .replace('á', 'a')
                            .replace('é', 'e')
                            .replace('í', 'i')
                            .replace('ó', 'o')
                            .replace('ú', 'u')
                        }
                        let rowValue = row[filter.id]
                        if (typeof rowValue === 'string') {
                          rowValue = row[filter.id]
                            .replace('á', 'a')
                            .replace('é', 'e')
                            .replace('í', 'i')
                            .replace('ó', 'o')
                            .replace('ú', 'u')
                        }
                        return new RegExp(filterValue, 'gi').test(
                          String(rowValue)
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                        )
                      }
                    } else {
                      let filterValue = filter.value
                      if (typeof filterValue === 'string') {
                        filterValue = filter.value
                          .replace('á', 'a')
                          .replace('é', 'e')
                          .replace('í', 'i')
                          .replace('ó', 'o')
                          .replace('ú', 'u')
                      }
                      let rowValue = row[filter.id]
                      if (typeof rowValue === 'string') {
                        rowValue = row[filter.id]
                          .replace('á', 'a')
                          .replace('é', 'e')
                          .replace('í', 'i')
                          .replace('ó', 'o')
                          .replace('ú', 'u')
                      }
                      return new RegExp(filterValue, 'gi').test(
                        String(rowValue)
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                      )
                    }
                  }}
                  columns={[
                    {
                      Header: 'Created',
                      accessor: 'createdAt',
                      headerClassName: 'question-input',
                      Filter: placeHolder,
                      width: 160,
                      Cell: ({ row }) => {
                        const date = format(row.createdAt.toDate(), 'dd/MM/yyyy HH:mm')
                        return date
                      },
                      style: {
                        fontFamily: 'Monospace'
                      }
                    },
                    {
                      Header: 'Passport',
                      accessor: 'user.passport',
                      Filter: placeHolder,
                      headerClassName: 'question-input',
                      width: 140,
                      style: {
                        fontWeight: 'bold',
                        letterSpacing: '1px'
                      }
                    },
                    {
                      Header: 'Fullname',
                      accessor: 'user.fullName',
                      Filter: placeHolder,
                      headerClassName: 'question-input'
                    },
                    {
                      Header: 'Email',
                      accessor: 'user.email',
                      Filter: placeHolder,
                      headerClassName: 'question-input',
                      className: 'text-muted'
                    },
                    {
                      Header: 'Company',
                      accessor: 'user.company',
                      Filter: placeHolder,
                      headerClassName: 'question-input'
                    },
                    {
                      Header: 'Type',
                      accessor: 'user',
                      Filter: placeHolder,
                      headerClassName: 'question-input',
                      className: 'text-info',
                      style: {
                        fontWeight: 'bold'
                      },
                      Cell: ({ row }) => {
                        return ROLES[row.user.type]?.name
                      }
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <div className='actions-right'>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                (
                                  <Tooltip id='tooltip'>
                                    Info
                                  </Tooltip>
                                )
                              }
                            >
                              <Button
                                onClick={() => {
                                  props.history.push(`/admin/user/${row._original.id}/info`)
                                }}
                                bsStyle='primary'
                                simple
                                icon
                              >
                                <i className='fa fa-info' />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                (
                                  <Tooltip id='tooltip'>
                                    Inductions
                                  </Tooltip>
                                )
                              }
                            >
                              <Button
                                onClick={() => {
                                  props.history.push(`/admin/user/${row._original.user.uid}/inductions`)
                                }}
                                bsStyle='success'
                                simple
                                icon
                              >
                                <i className='fa fa-list-ul' />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                (
                                  <Tooltip id='tooltip'>
                                    Incidents
                                  </Tooltip>
                                )
                              }
                            >
                              <Button
                                onClick={() => {
                                  props.history.push(`/admin/user/${row._original.id}/incidents`)
                                }}
                                bsStyle='warning'
                                simple
                                icon
                              >
                                <i className='fa fa-warning' />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        )
                      }
                    }
                  ]}
                  defaultSorted={[{
                    id: 'createdAt',
                    desc: true
                  }]}
                  defaultPageSize={20}
                  showPaginationBottom
                  className='-striped -highlight'
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CSVLink
              separator=';'
              filename='users.csv'
              style={{ float: 'right', marginRight: 20 }}
              data={data}
            >
              DOWNLOAD CSV
            </CSVLink>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}
