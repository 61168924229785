import React, { useContext, useEffect, useState } from 'react'
// Router
import {
  useLocation,
  useParams
} from 'react-router-dom'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'config/firebase'
// Bootstrap
import {
  Nav,
  Badge,
  NavItem,
  Tab
} from 'react-bootstrap'
// Components
import Card from 'components/Card/Card.jsx'
import { UserInfo } from 'components/User/UserInfo'
import { UserInductions } from 'components/User/UserInductions'
import { UserIncidents } from 'components/User/UserIncidents/UserIncidents'

export const User = (props) => {
  const location = useLocation()
  const context = useContext(UserContext)
  const { userId, tab } = useParams()
  const [selectedTab, setSelectedTab] = useState(tab)
  const [user, setUser] = useState()

  useEffect(() => {
    firebase.firestore()
      .collection('users')
      .doc(userId)
      .onSnapshot(snapshot => {
        if (!snapshot.empty && snapshot.data()) {
          context.setActiveRouteName(snapshot.data().fullName)
          setUser({
            id: snapshot.id,
            ...snapshot.data()
          })
        } else {
          setUser()
        }
      })
  }, [])

  useEffect(() => {
    setSelectedTab(tab)
  }, [location])

  const getBadge = (tab) => {
    if (
      context.notifications.filter(
        notification => notification.userTab === tab
      ).length > 0
    ) {
      return (
        <Badge
          style={{ position: 'absolute', top: -4, left: -4 }}
          className='badge-danger'
        >
          {
              context.notifications.filter(
                notification => notification.userTab === tab
              ).length
            }
        </Badge>
      )
    } else {
      return null
    }
  }

  return (
    <div className='main-content'>
      <Tab.Container
        activeKey={selectedTab}
        defaultActiveKey={selectedTab}
        onSelect={t => props.history.push(`/admin/user/${userId}/${t}`)}
      >
        <div>
          <div className='nav-container'>
            <Nav bsStyle='tabs' bsClass='nav nav-icons'>
              <NavItem eventKey='info'>
                <h2 style={styles.tabTitle}>
                  {getBadge('info')}
                  <b className='text-primary'>Info</b>
                </h2>
                {selectedTab === 'info' && (
                  <div
                    style={styles.underline}
                  />
                )}
              </NavItem>
              <NavItem eventKey='inductions'>
                <h2 style={styles.tabTitle}>
                  {getBadge('inductions')}
                  <b className='text-primary'>Inductions</b>
                </h2>
                {selectedTab === 'inductions' && (
                  <div
                    style={styles.underline}
                  />
                )}
              </NavItem>
              <NavItem eventKey='incidents'>
                <h2 style={styles.tabTitle}>
                  {getBadge('incidents')}
                  <b className='text-primary'>Incidents</b>
                </h2>
                {selectedTab === 'incidents' && (
                  <div
                    style={styles.underline}
                  />
                )}
              </NavItem>
            </Nav>
          </div>
          <div style={styles.tabContent}>
            {selectedTab === 'info' && (
              <Card
                title='User Information'
                category='User information details'
                content={<UserInfo user={user} />}
              />
            )}
            {selectedTab === 'inductions' && (
              <Card
                title='Inductions'
                category='Inductions List'
                content={<UserInductions userId={userId} />}
              />
            )}
            {selectedTab === 'incidents' && (
              <Card
                title='Incidents'
                category='Incicents List'
                content={<UserIncidents user={user} />}
              />
            )}
          </div>
        </div>
      </Tab.Container>
    </div>
  )
}

const styles = {
  underline: {
    width: '100%',
    height: '5px',
    backgroundColor: '#48B1D3'
  },
  tabTitle: {
    marginBottom: '2px'
  },
  tabContent: {
    marginTop: 20
  }
}
